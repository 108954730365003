<template>
    <div class="form-maker__fw">
        <!-- Insert section at the top of the sections -->
        <y-form-maker-add-bar
            v-if="model.sections && model.sections.length && (canEdit || canCreate)"
            insert
            class="always-show"
            @open="$refs.addSectionModal.open('insert', null, 'first')"
        />

        <!-- Sections -->
        <draggable
            v-if="hasSection"
            v-bind="dragOptions"
            tag="div"
            handle="div .panel header .title .md-menu"
            :list="model.sections"
        >
            <template v-for="(section, index) in model.sections">
                <div
                    v-if="!section.deleted"
                    :key="generateId(index)"
                    class="form-maker__section"
                >
                    <y-panel
                        :key="generateId(index)"
                        :title="section.title"
                        icon="md-menu"
                    >
                        <template
                            v-if="(canEdit || canCreate)"
                            slot="functions"
                        >
                            <y-button
                                size="sm"
                                @click.native.prevent="addComponent(index, 'add', 'question')"
                            >
                                {{ $t('form-maker.section.toolbar.add_question') }}
                            </y-button>

                            <y-button
                                size="sm"
                                @click.native.prevent="addComponent(index, 'add', 'title')"
                            >
                                {{ $t('form-maker.section.toolbar.add_title') }}
                            </y-button>

                            <y-button
                                size="sm"
                                icon="md-pencil"
                                @click.native.prevent="$refs.addSectionModal.open('edit', section.title, index)"
                            />

                            <y-button
                                size="sm"
                                color="red"
                                icon="md-delete"
                                @click.native.prevent="$refs.deleteSectionModal.open(index, 'section', section.id)"
                            />
                        </template>

                        <draggable
                            v-if="hasComponents(index)"
                            ref="Draggable"
                            tag="div"
                            handle="div .panel header .title .md-menu"
                            :list="model.sections[index].components"
                        >
                            <template v-for="(component, i) in model.sections[index].components">
                                <div
                                    v-if="!component.deleted"
                                    :key="generateId(i)"
                                    class="form-maker__wrapper__form"
                                >
                                    <y-panel
                                        class="mb0 form-maker__wrapper__panel"
                                        icon="md-menu"
                                    >
                                        <template
                                            v-if="(canEdit || canCreate)"
                                            slot="functions"
                                        >
                                            <y-button
                                                size="sm"
                                                color="red"
                                                icon="md-delete"
                                                @click.native.prevent="$refs.deleteComponentModal.open(index, component.type === 'title' ? 'title' : 'component', component.id, i)"
                                            />
                                        </template>

                                        <y-form-maker-title
                                            v-if="component.type === 'title'"
                                            :key="`form-component-${i * random}`"
                                            v-model="model.sections[index].components[i]"
                                            :can-edit="(canEdit || canCreate)"
                                        />

                                        <y-form-maker-question
                                            v-else
                                            :key="`form-component-${i * random}`"
                                            v-model="model.sections[index].components[i]"
                                            :types="types"
                                            :uploader-rules="uploaderRules"
                                            :can-edit="(canEdit || canCreate)"
                                        />
                                    </y-panel>
                                </div>
                            </template>
                        </draggable>

                        <!-- Empty components -->
                        <y-empty-state
                            v-else
                            height="250"
                            :message="$t('form-maker.question.empty')"
                        />
                    </y-panel>

                    <!-- Insert section between sections -->
                    <y-form-maker-add-bar
                        v-if="model.sections && index !== model.sections.length - 1 && (canEdit || canCreate)"
                        insert
                        @open="$refs.addSectionModal.open('insert', null, index)"
                    />
                </div>
            </template>
        </draggable>

        <!-- Empty State in Sections -->
        <y-empty-state
            v-else
            height="400"
            :message="$t('form-maker.section.empty')"
        >
            <y-form-maker-add-bar
                class="form-maker__add-bar"
                :label="$t('form-maker.section.add.title')"
                @open="$refs.addSectionModal.open('add')"
            />
        </y-empty-state>

        <!-- Add new section (At the end of the sections) -->
        <y-form-maker-add-bar
            v-if="model.sections && model.sections.length"
            insert
            class="always-show"
            @open="$refs.addSectionModal.open('insert', null, 'last')"
        />

        <!-- Add Section Modal -->
        <y-add-section-modal
            ref="addSectionModal"
            @done="addSection"
        />
        <y-delete-modal
            ref="deleteSectionModal"
            @confirm="deleteSection"
        />
        <y-delete-modal
            ref="deleteComponentModal"
            @confirm="deleteComponent"
        />
    </div>
</template>

<script>

    import FormWrapperMixin from '@/modules/form-maker/mixins/FormWrapper';

    export default {
        name: 'FormWrapper',

        mixins: [
            FormWrapperMixin,
        ],

        props: {
            types        : Array,
            uploaderRules: Array,
            metadata     : [Object, Array],
        },

        computed: {
            /**
             * Draggable Options
             */
            dragOptions() {
                return {
                    group: 'sections',
                };
            },

            /**
             * Check if user can edit the form
             */
            canEdit() {
                return this.$route.params.id ? this.metadata && this.metadata.can_edit : false;
            },

            /**
             * Check if a user can create the form
             */
            canCreate() {
                return this.metadata && this.metadata.can_create;
            },
        },
    };

</script>
