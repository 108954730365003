<template>
    <y-panel :title="$t('form-maker.setting.title')">
        <y-form-field
            v-model="model.title"
            type="text"
            name="title"
            validation="required"
            :readonly="!(canEdit || canCreate)"
        />
        <y-form-field
            v-model="model.slug"
            type="text"
            name="slug"
            :readonly="!(canEdit || canCreate)"
        />
        <y-form-field
            v-model="model.description"
            type="textarea"
            name="description"
            no-resize
            :readonly="!(canEdit || canCreate)"
        />
    </y-panel>
</template>

<script>

    import YFormField from '@deps/form/FormField';

    export default {
        name: 'FormMakerSetting',

        components: {
            YFormField,
        },

        props: {
            value   : [Object, Array],
            metadata: [Object, Array],
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        computed: {
            /**
             * Check if user can edit the form
             */
            canEdit() {
                return this.$route.params.id ? this.metadata && this.metadata.can_edit : false;
            },
            
            /**
             * Check if a user can create the form
             */
            canCreate() {
                return this.metadata && this.metadata.can_create;
            },
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },
    };

</script>
