<template>
    <y-modal
        ref="Modal"
        :title="$t(`form-maker.responses.delete.${isBatch ? 'batch_title' : 'form_title'}`)"
    >
        <div slot="body">
            <p class="mb0">
                {{ $t(`form-maker.responses.delete.${isBatch ? 'batch_text' : 'form_text'}`) }}
            </p>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                @click="close"
            >
                {{ $t('button.close') }}
            </y-button>
            <y-button
                color="red"
                @click.prevent.native="deleteItem"
            >
                {{ $t('button.confirm') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {
        name: 'DeleteAnswersModal',

        components: {
            YModal,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                isBatch: null,
            };
        },

        methods: {
            /**
             * Open modal
             *
             * @param batch
             */
            open(batch) {
                this.$set(this, 'isBatch', batch);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Emit Add Section event
             */
            deleteItem() {
                this.$emit('confirm');
                this.close();
            },
        },
    };

</script>
