<template>
    <y-panel
        :title="summary ? element.title : null"
        :subtitle="summary ? `(${digitHelper(totalAnswers)})` : null"
    >
        <template
            v-if="summary"
            slot="functions"
        >
            <y-button
                type="button"
                size="sm"
                @click.prevent="downloadChart('dropDownChart')"
            >
                {{ $t('form-maker.responses.buttons.download_chart') }}
            </y-button>
        </template>
        <template v-if="summary">
            <y-chart
                ref="dropDownChart"
                class="answer-pie"
                :series="series"
                :options="options"
            />
        </template>

        <template v-if="component">
            <template v-if="answers && answers.length">
                <p
                    v-for="(answer, index) in answers"
                    :key="index"
                    class="answer-input is-component"
                >
                    <span>
                        <span
                            v-for="(item, i) in answer.value"
                            :key="i"
                            class="answer-value"
                        >
                            {{ item }}
                        </span>
                    </span>
                    <span class="answer-count">{{ answer.count | digits }}</span>
                </p>
            </template>

            <template v-else>
                <y-empty-state
                    height="150"
                    class="mb0"
                    no-image
                    :title="$t('form-maker.responses.individual.empty_response')"
                />
            </template>
        </template>

        <template v-else-if="!summary && !component">
            <y-form-field
                v-model="model.value"
                type="select"
                :options="element.options"
                label-field="title"
                value-field="id"
                allow-empty
                :placeholder="$t('specs.feature.select.placeholder')"
                :label="element.title"
                :validation="element.is_required ? 'required' : ''"
                :disabled="readonly"
            />
        </template>
    </y-panel>
</template>

<script>
    import { digits } from '@nodes/helpers/number';
    import TypeFrontMixin from '@/modules/form-maker/mixins/TypeFront';
    import YChart from '@deps/Chart';

    export default {
        name: 'FormMakerPreviewDropdown',

        components: {
            YChart,
        },

        mixins: [TypeFrontMixin],

        computed: {
            /**
             * Total of the Answers
             */
            totalAnswers() {
                let count = 0;
                Object.keys(this.answers).forEach((key) => {
                    count += this.answers[key].value;
                });
                return count;
            },

            /**
             * Label of the Chart
             */
            labels() {
                return Object.keys(this.answers).map((i) => this.answers[i].title);
            },

            /**
             * Options of the Chart
             */
            options() {
                const options = {
                    legend: {
                        orient   : 'vertical',
                        left     : 'left',
                        textStyle: {
                            fontFamily: this.$i18n.locale === 'fa' ? '"Vazir", sans-serif' : 'sans-serif',
                        },
                    },
                };
                return options;
            },

            /**
             * Series of the Chart
             */
            series() {
                const data = this.answers;
                return [
                    {
                        name : this.title,
                        type : 'pie',
                        label: {
                            show      : true,
                            position  : 'outside',
                            color     : 'rgba(96, 125, 139, 1)',
                            fontFamily: '"Vazir", sans-serif',
                            formatter : ((params) => `${params.data.name}: ${digits((params.percent), this.$i18n.locale)}% (${digits(params.data.value, this.$i18n.locale)})`),
                        },
                        data,
                    },
                ];
            },
        },

        /**
         * Add Answer to the model
         */
        created() {
            if (this.answers) {
                Object.keys(this.answers).forEach((key) => {
                    this.$set(this.model, 'value', this.answers[key]);
                });
            }
        },
    };

</script>
