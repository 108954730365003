<template>
    <y-panel
        :title="summary ? element.title : null"
        :subtitle="summary ? answers ? `(${digitHelper(Object.keys(answers).length)})` : `(${digitHelper('0')})` : null"
        class="uploader"
    >
        <div v-if="answers || readonly || summary || component">
            <template v-if="answers">
                <a
                    v-for="(file, index) in answers"
                    :key="index"
                    :href="file.link"
                    class="answer-file"
                    :class="answers.length > index ? 'mb10' : 'mb0'"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i class="icon md-file" />
                    {{ file.original_name }}
                </a>
            </template>

            <template v-else>
                <y-empty-state
                    height="150"
                    class="mb0"
                    no-image
                    :message="$t('form-maker.responses.individual.empty_response')"
                />
            </template>
        </div>

        <y-form-uploader
            v-else
            :key="element.id"
            ref="uploader"
            v-model="model.value"
            :label="element.title"
            class="mb0"
            :max-file-size="element.max_volume_files"
            :accepted-files="element.allowed_extensions"
            :max-files="element.max_number_files"
            :payload="element.payload"
            :validation="element.is_required ? 'required' : ''"
        />
    </y-panel>
</template>

<script>
    import TypeFrontMixin from '@/modules/form-maker/mixins/TypeFront';
    import YFormUploader from '@deps/form/elements/Uploader';

    export default {
        name: 'YFormMakerComponentFile',

        components: {
            YFormUploader,
        },

        mixins: [TypeFrontMixin],

        computed: {
            /**
             * Check if object is empty
             */
            isEmpty() { // eslint-disable-line consistent-return
                if ((typeof this.answers === 'object') && (this.answers !== null)) {
                    return (Object.entries(this.answers).length === 0 && this.answers.constructor === Object);
                }
                return false;
            },
        },
    };

</script>
