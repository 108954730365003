// From Maker Wrapper Mixin

import Draggable from 'vuedraggable';
import YEmptyState from '@deps/EmptyState';

import YFormMakerTitle from '@/modules/form-maker/components/types/Title';
import YFormMakerQuestion from '@/modules/form-maker/components/types/Question';
import YFormMakerAddBar from '@/modules/form-maker/components/AddBar';

// Modals
import YAddSectionModal from '@/modules/form-maker/components/modal/AddSection';
import YDeleteModal from '@/modules/form-maker/components/modal/Delete';

export default {
    name: 'FormWrapperMixin',

    props: {
        value: [Object, Array],
    },

    components: {
        Draggable,
        YEmptyState,
        YFormMakerTitle,
        YFormMakerQuestion,
        YFormMakerAddBar,
        YAddSectionModal,
        YDeleteModal,
    },

    computed: {
        /**
         * Create a random integer
         */
        random() {
            return Math.floor(Math.random() * (9999999 - 1000000 + 1) + 1000000);
        },
            
        /**
         * Has Deleted Section
         */
        hasDeleted() {
            let has = false;
            if (this.model && this.model.sections) {
                this.model.sections.forEach((i) => {
                    if (i.deleted) {
                        has = true;
                    }
                });
            }
            return has;
        },

        /**
         * Has Not Deleted Section
         */
        hasSection() {
            let has = false;
            if (this.model && this.model.sections) {
                this.model.sections.forEach((i) => {
                    if (!i.deleted) {
                        has = true;
                    }
                });
            }
            return has;
        },
    },

    /**
     * @inheritdoc
     */
    data() {
        return {
            model     : this.value || {},
            components: [],

            sortedInFetch: true,

            raw: {
                question: {
                    type       : 'question',
                    title      : null,
                    placeholder: null,
                    is_required: false,
                    deleted    : false,
                },

                title: {
                    type       : 'title',
                    title      : null,
                    description: null,
                    deleted    : false,
                },
            },
        };
    },

    watch: {

        value: {
            /**
             * Handle watch value
             *
             * @param newVal
             * @param oldVal
             */
            handler(newVal, oldVal) {
                if (!this.isEqual(newVal, oldVal)) {
                    this.$set(this, 'model', { ...this.model, ...newVal });
                }
            },
            deep: true,
        },

        model: {
            /**
             * Emit input when model changes
             */
            handler() {
                this.$emit('input', this.model);
            },
            deep: true,
        },

        'model.sections': {
            /**
             * Run sort in created
             */
            handler() {
                if (this.sortedInFetch) {
                    this.sort(this.model.sections);
                    this.sortedInFetch = false;
                }
            },
            deep: true,
        },

    },

    /**
     * @inheritdoc
     */
    created() {
        if (this.$route.params.id && this.model.sections) {
            this.sort(this.model.sections);
        }
    },

    methods: {
        /**
         * Sections: Add New Section
         *
         * @param {string} method | 'insert', 'add', 'edit'
         * @param {string} title | Title of the Section
         * @param {number} index | Index of the desire place of new section
         */
        addSection(method, title, index) {
            const section = {
                title,
                components: [],
                deleted   : false,
            };
            if (method === 'insert') {
                if (index === 'first') {
                    this.$set(this.model, 'sections', [this.cloneDeep(section), ...this.model.sections]);
                } else if (index === 'last') {
                    this.$set(this.model, 'sections', [...this.model.sections, this.cloneDeep(section)]);
                } else {
                    this.model.sections.splice(index + 1, 0, this.cloneDeep(section));
                }
            }

            if (method === 'edit') {
                this.$set(this.model.sections[index], 'title', title);
            }
            if (method === 'add') {
                if (!this.model.sections) {
                    this.$set(this.model, 'sections', []);
                }
                this.$set(this.model, 'sections', [...this.model.sections, this.cloneDeep(section)]);
            }
        },

        /**
         * Sections: Delete
         *
         * @param {number} index | The index of the deleted section
         */
        deleteSection(index) {
            this.$set(this.model.sections[index], 'deleted', true);
        },

        /**
         * Types: Add new Type component
         *
         * @param index
         * @param method
         * @param type
         */
        addComponent(index, method, type) {
            if (!this.model.sections[index].components) {
                this.$set(this.model.sections[index], 'components', []);
            }
                    
            if (method === 'add') {
                this.$set(this.model.sections[index], 'components', [...this.model.sections[index].components, this.cloneDeep(this.raw[type])]);
            }

            if (method === 'insert') {
                this.$set(this, 'model', [...this.model, this.cloneDeep(this.raw[type])]);
            }
            this.sort(this.model.sections);
        },

        /**
         * Has Not Deleted Components
         *
         * @param index
         */
        hasComponents(index) {
            let has = false;
            if (this.model.sections[index].components) {
                this.model.sections[index].components.forEach((i) => {
                    if (!i.deleted) {
                        has = true;
                    }
                });
            }
            return has;
        },

        /**
         * Types: Delete
         *
         * @param {number} sectionIndex 
         * @param {number} componentIndex 
         */
        deleteComponent(sectionIndex, componentIndex) {
            this.$set(this.model.sections[sectionIndex].components[componentIndex], 'deleted', true);
        },

        /**
         * Sort
         *
         * @param sections
         */
        sort(sections) {
            if (sections) {
                sections.forEach((i, index) => {
                    this.$set(this.components, `${index}`, this.sortComponents(i.components));
                });
            }
        },
            
        /**
         * Sort Components
         *
         * @param components
         */
        sortComponents(components) {
            const sorted = components.sort((a, b) => {
                if ( a.order < b.order ) {
                    return -1;
                }
                if ( a.order > b.order ) {
                    return 1;
                }
                return 0;
            });
            return sorted;
        },
    },
};
