<template>
    <y-row class="form-maker__responses__summary">
        <y-col
            sm="12"
            md="8"
            center
        >
            <y-loading
                :active="$wait.is('fetch-summary')"
                height="300px"
            >
                <template v-if="forms && meta && meta.batches_count">
                    <div
                        v-for="section in forms.sections"
                        :key="`section-${section.id}`"
                    >
                        <template v-for="component in section.components">
                            <component
                                :is="componentType(component.type)"
                                v-if="component.type !== 'title'"
                                :key="component.id"
                                :element="component"
                                summary
                                readonly
                            />
                        </template>
                    </div>
                </template>

                <template v-else>
                    <y-empty-state
                        height="300"
                        class="mb0"
                        :title="$t('form-maker.responses.empty_form')"
                    />
                </template>
            </y-loading>
        </y-col>
    </y-row>
</template>

<script>
    import FormComponentMixin from '@/modules/form-maker/mixins/FormComponent';
    import YEmptyState from '@deps/EmptyState';

    export default {
        name: 'ResponseSummaryTab',

        components: {
            YEmptyState,
        },

        mixins: [
            FormComponentMixin,
        ],

        props: {
            forms: [Array, Object],
            meta : [Array, Object],
        },
    };
</script>
