// From Maker Component Mixin

import { capitalize } from '@nodes/helpers/string';
import YFormMakerComponentTitle from '@/modules/form-maker/components/types/front/Title';
import YFormMakerComponentInput from '@/modules/form-maker/components/types/front/Input';
import YFormMakerComponentParagraph from '@/modules/form-maker/components/types/front/Paragraph';
import YFormMakerComponentDropdown from '@/modules/form-maker/components/types/front/Dropdown';
import YFormMakerComponentCheckboxes from '@/modules/form-maker/components/types/front/Checkboxes';
import YFormMakerComponentRadio from '@/modules/form-maker/components/types/front/Radio';
import YFormMakerComponentDateTime from '@/modules/form-maker/components/types/front/DateTime';
import YFormMakerComponentRange from '@/modules/form-maker/components/types/front/Range';
import YFormMakerComponentFile from '@/modules/form-maker/components/types/front/File';

const types = {
    YFormMakerComponentTitle,
    YFormMakerComponentInput,
    YFormMakerComponentParagraph,
    YFormMakerComponentDropdown,
    YFormMakerComponentCheckboxes,
    YFormMakerComponentRadio,
    YFormMakerComponentDateTime,
    YFormMakerComponentRange,
    YFormMakerComponentFile,
};

export default {
    name: 'FormComponentMixin',

    components: {
        ...types,
    },

    /**
     * @inheritdoc
     */
    data() {
        return {
            model     : [],
            form      : null,
            metadata  : null,
            components: [],
        };
    },

    methods: {
        /**
         * Component Type
         *
         * @param type
         */
        componentType(type) {
            let name = type;
            if (type === 'date_time') {
                name = 'DateTime';
            }
            return `YFormMakerComponent${capitalize(name)}`;
        },

        /**
         * Sort Sections
         *
         * @param sections
         */
        sortSections(sections) {
            sections.forEach((i, index) => {
                this.$set(this.components, `${index}`, this.sortComponents(i.components));
            });
        },
            
        /**
         * Sort Components
         *
         * @param components
         */
        sortComponents(components) {
            const sorted = components.sort((a, b) => {
                if ( a.order < b.order ) {
                    return -1;
                }
                if ( a.order > b.order ) {
                    return 1;
                }
                return 0;
            });
            return sorted;
        },
    },
};
