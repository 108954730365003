<template>
    <y-modal
        ref="Modal"
        :title="title && !newSection ? $t('form-maker.section.edit.title') : $t('form-maker.section.add.title')"
    >
        <div slot="body">
            <form
                id="add-section-form"
                @submit.prevent="addSection"
            >
                <y-form-field
                    v-model="title"
                    type="text"
                    name="title"
                    validation="required"
                />
            </form>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                @click="close"
            >
                {{ $t('button.close') }}
            </y-button>
            <y-button
                color="blue"
                :disabled="!title"
                form="add-section-form"
                @click.prevent.native="addSection"
            >
                {{ title ? $t('button.edit') : $t('button.create') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>

    import { YModal, YFormField } from '@deps';

    export default {
        name: 'AddModal',

        components: {
            YModal,
            YFormField,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                title     : null,
                type      : null,
                index     : null,
                newSection: true,
            };
        },

        methods: {
            /**
             * Open modal
             *
             * @param type
             * @param title
             * @param index
             */
            open(type, title, index) {
                this.$set(this, 'title', title);
                this.$set(this, 'type', type);
                this.$set(this, 'index', index);
                this.$set(this, 'newSection', !title);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Reset the title
             */
            reset() {
                this.title = null;
            },

            /**
             * Emit Add Section event
             */
            addSection() {
                this.$emit('done', this.type, this.title, this.index);
                this.reset();
                this.close();
            },
        },
    };

</script>
