<template>
    <y-row class="form-maker__responses__individual">
        <y-col
            sm="12"
            md="8"
            center
        >
            <y-loading
                :active="$wait.is('fetch-response')"
                height="300px"
            >
                <template v-if="form">
                    <y-panel
                        v-for="(section, index) in form.sections"
                        :key="`section-${section.id}`"
                        :title="section.title"
                        icon="icon md-checkbox-blank-circle"
                    >
                        <template v-for="component in components[index]">
                            <component
                                :is="componentType(component.type)"
                                v-if="component.type !== 'title'"
                                :key="component.id"
                                class="form-maker__preview-component"
                                :element="component"
                                readonly
                            />
                        </template>
                    </y-panel>
                </template>
            </y-loading>
        </y-col>

        <y-col
            sm="12"
            md="4"
        >
            <y-loading
                :active="$wait.is('fetch-responser')"
                height="300px"
            >
                <y-panel
                    v-if="users"
                    :title="$t('form-maker.responses.individual.users_list')"
                >
                    <y-form-field
                        v-model="user"
                        type="select"
                        :options="users.batches"
                        label-field="responder_title"
                        value-field="id"
                        no-label
                        @select="selectedUser($event)"
                    />

                    <y-row>
                        <y-col>
                            <y-button
                                id="downlaod-excel-button"
                                type="button"
                                color="green"
                                block
                                loading-on="download-excel"
                                @click.prevent="downloadUserResponseExcel"
                            >
                                {{ $t('form-maker.responses.buttons.download_excel') }}
                            </y-button>
                        </y-col>
                        <y-col>
                            <y-button
                                id="downlaod-excel-button"
                                type="button"
                                block
                                loading-on="download-exported-excel"
                                :disabled="metadata && !metadata.exported_excel"
                                @click.prevent="downloadUserResponseExportedExcel"
                            >
                                {{ $t('form-maker.responses.buttons.download_exported_excel') }}
                            </y-button>
                        </y-col>
                        <y-col>
                            <y-button
                                id="clear-response-button"
                                type="button"
                                color="red"
                                block
                                loading-on="delete-user-answer"
                                @click.prevent="$refs.deleteUserAnswerModal.open(true)"
                            >
                                {{ $t('form-maker.responses.buttons.delete_user_answer') }}
                            </y-button>
                        </y-col>
                    </y-row>
                </y-panel>

                <y-panel
                    v-if="userInfo"
                    :title="$t('form-maker.responses.individual.user_info.title')"
                >
                    <y-row>
                        <y-col>
                            <div class="detail-item">
                                <div class="title">
                                    {{ $t('form-maker.responses.individual.user_info.name') }}
                                </div>
                                <div class="value">
                                    <router-link :to="routeToProfile">
                                        {{ userInfo.name_full ? userInfo.name_full : `-` }}
                                    </router-link>
                                </div>
                            </div>
                        </y-col>
                        <y-col>
                            <div class="detail-item">
                                <div class="title">
                                    {{ $t('form-maker.responses.individual.user_info.mobile') }}
                                </div>
                                <div class="value">
                                    {{ userInfo.mobile ? digits(userInfo.mobile, $i18n.locale) : `-` }}
                                </div>
                            </div>
                        </y-col>
                        <y-col>
                            <div class="detail-item">
                                <div class="title">
                                    {{ $t('form-maker.responses.individual.user_info.email') }}
                                </div>
                                <div class="value">
                                    {{ userInfo.email ? userInfo.email : `-` }}
                                </div>
                            </div>
                        </y-col>
                    </y-row>
                </y-panel>
            </y-loading>
        </y-col>

        <y-delete-user-answer-modal
            ref="deleteUserAnswerModal"
            @confirm="deleteAllResponses"
        />
    </y-row>
</template>

<script>
    import { digits } from '@nodes/helpers/number';
    import FormComponentMixin from '@/modules/form-maker/mixins/FormComponent';
    import { YFormField } from '@deps';
    import YDeleteUserAnswerModal from '@/modules/form-maker/components/modal/DeleteAnswers';

    export default {
        name: 'ResponseIndividualTab',

        components: {
            YFormField,
            YDeleteUserAnswerModal,
        },

        mixins: [
            FormComponentMixin,
        ],

        /**
         * @inheritdoc
         */
        data() {
            return {
                users   : null,
                metadata: null,
                user    : null,
                userInfo: null,
            };
        },

        computed: {
            /**
             * Route to Profile
             */
            routeToProfile() {
                return {
                    name  : 'persons-users-profile',
                    params: {
                        id: this.userInfo.id,
                    },
                };
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.fetch();
            this.fetchResponser();
        },

        methods: {
            digits,

            /**
             * Fetch data
             * 
             * @param {string} id - Id of the user
             */
            fetch(id) {
                this.$wait.start('fetch-response');
                const params = {
                    id: this.$route.params.id,
                };
                if (id) {
                    params.batch_id = id;
                }
                return this.$services.FormMaker.formsUserAnswers(params).then((response) => {
                    if (response.data.results) {
                        this.model = [];
                        this.$set(this, 'form', response.data.results);
                        this.$set(this, 'metadata', response.data.metadata);
                        this.sortSections(this.form.sections);
                    }
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetch-response');
                });
            },

            /**
             * Fetch Responser
             */
            fetchResponser() {
                this.$wait.start('fetch-responser');
                this.$services.single('form-maker-form', this.$route.params.id, { including: ['batches'] }).then((response) => {
                    this.$set(this, 'users', response.data.results);
                    if (!this.user && this.users.batches.length) {
                        this.$set(this, 'user', this.users.batches[0].id);
                        this.$set(this, 'userInfo', this.users.batches[0].user);
                    }
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetch-responser');
                });
            },

            /**
             * Set User after user change
             * 
             * @param {object} user - Object of the user
             */
            selectedUser(user) {
                this.fetch(user.id);
                this.$set(this, 'userInfo', user.user);
            },

            /**
             * Download User Response in Excel
             */
            downloadUserResponseExcel() {
                this.$wait.start('download-excel');
                const params = {
                    tab     : 'exports',
                    provider: 'form_answers',
                    form_id : this.$route.params.id,
                    batch_id: this.user,

                };
                return this.$services.DataTrader.dataTradesSave(params).then(() => {
                    this.$toast.success(this.$t('form-maker.responses.messages.download'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('download-excel');
                });
            },

            /**
             * Download User Response in Excel
             */
            downloadUserResponseExportedExcel() {
                this.$wait.start('download-exported-excel');
                const params = {
                    tab: 'exports',
                    id : this.metadata.exported_excel,

                };
                return this.$services.DataTrader.downloadFile(params).then((response) => {
                    window.open(response.data.metadata.redirect.url, response.data.metadata.redirect.target);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('download-exported-excel');
                });
            },

            /**
             * Delete User Response
             */
            deleteAllResponses() {
                this.$wait.start('delete-user-answer');
                const params = {
                    id      : this.$route.params.id,
                    batch_id: this.user,
                };
                return this.$services.FormMaker.removeAnswers(params).then(() => {
                    this.$toast.success(this.$t('form-maker.responses.messages.delete_response_user'));
                    this.$set(this, 'user', null);
                    this.fetch();
                    this.fetchResponser();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('delete-user-answer');
                });
            },
        },
    };
</script>
