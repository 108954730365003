<template>
    <y-panel
        :title="summary ? element.title : null"
        :subtitle="summary ? `(${digitHelper(totalAnswers)})` : null"
        class="field form-wizard-custom-label"
    >
        <template
            v-if="summary"
            slot="functions"
        >
            <y-button
                type="button"
                size="sm"
                @click.prevent="downloadChart('checkboxChart')"
            >
                {{ $t('form-maker.responses.buttons.download_chart') }}
            </y-button>
        </template>

        <template v-if="summary">
            <y-chart
                ref="checkboxChart"
                :series="series"
                :x-axis-type="xAxisType"
                :y-axis-label-data="yAxisLabelData"
                :options="options"
            />
        </template>

        <template v-if="component">
            <template v-if="answers && answers.length">
                <p
                    v-for="(answer, index) in answers"
                    :key="index"
                    class="answer-input is-component"
                >
                    <span>
                        <span
                            v-for="(item, i) in answer.value"
                            :key="i"
                            class="answer-value"
                        >
                            {{ item }}
                        </span>
                    </span>
                    <span class="answer-count">{{ answer.count | digits }}</span>
                </p>
            </template>

            <template v-else>
                <y-empty-state
                    height="150"
                    class="mb0"
                    no-image
                    :title="$t('form-maker.responses.individual.empty_response')"
                />
            </template>
        </template>

        <template v-else-if="!summary && !component">
            <label :class="{'required': element.is_required}">{{ element.title }}</label>
            <template v-for="item in element.options">
                <y-form-field
                    :key="item.id"
                    v-model="model.value[item.id]"
                    type="checkbox"
                    :label="item.title"
                    :disabled="readonly"
                />
            </template>
        </template>
    </y-panel>
</template>

<script>
    import { digits } from '@nodes/helpers/number';
    import YChart from '@deps/Chart';
    import TypeFrontMixin from '@/modules/form-maker/mixins/TypeFront';

    export default {
        name: 'FormMakerPreviewCheckboxes',

        components: {
            YChart,
        },

        mixins: [TypeFrontMixin],

        computed: {
            /**
             * Total of the Answers
             */
            totalAnswers() {
                let count = 0;
                Object.keys(this.answers).forEach((key) => {
                    count += this.answers[key].value;
                });
                return count;
            },

            /**
             * Generate xAxis Label
             */
            xAxisType() {
                return 'value';
            },

            /**
             * yAxis Data
             */
            yAxisLabelData() {
                return Object.keys(this.answers).map((i) => `(${this.digitHelper(Math.round((this.answers[i].value * 100) / this.totalAnswers))}% - ${this.digitHelper(this.answers[i].value)}) ${this.answers[i].name}`);
            },

            /**
             * Chart Options
             */
            options() {
                return {
                    tooltip: {
                        show: false,
                    },
                    xAxis: {
                        min        : 0,
                        max        : ((value) => value.max + 1),
                        splitNumber: 50,
                        minInterval: 1,
                        axisLabel  : {
                            formatter: ((params) => `${digits((params), this.$i18n.locale)}`),
                        },
                    },
                };
            },

            /**
             * Series of the widget
             */
            series() {
                const data = this.answers;
                return [
                    {
                        type: 'bar',
                        data,
                    },
                ];
            },
        },

        /**
         * Create empty model
         */
        created() {
            this.$set(this.model, 'value', {});
            if (this.answers) {
                Object.keys(this.answers).forEach((key) => {
                    this.$set(this.model.value, `${this.answers[key]}`, 1);
                });
            }
        },
    };

</script>
