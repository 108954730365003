<template>
    <y-panel
        :title="summary ? element.title : null"
        :subtitle="summary ? `(${digitHelper(totalAnswers)})` : null"
        class="field form-wizard-custom-label"
    >
        <template
            v-if="summary"
            slot="functions"
        >
            <y-button
                type="button"
                size="sm"
                @click.prevent="downloadChart('rangeChart')"
            >
                {{ $t('form-maker.responses.buttons.download_chart') }}
            </y-button>
        </template>

        <template v-if="summary">
            <y-chart
                ref="rangeChart"
                :series="series"
                :x-axis-label="xAxisLabel"
                :y-axis-label="yAxisLabel"
                :options="options"
            />
        </template>

        <template v-if="component">
            <template v-if="answers && answers.length">
                <p
                    v-for="(answer, index) in answers"
                    :key="index"
                    class="answer-input is-component"
                >
                    <span>{{ answer.value | digits }}</span>
                    <span class="answer-count">{{ answer.count | digits }}</span>
                </p>
            </template>

            <template v-else>
                <y-empty-state
                    height="150"
                    class="mb0"
                    no-image
                    :title="$t('form-maker.responses.individual.empty_response')"
                />
            </template>
        </template>

        <template v-else-if="!summary && !component">
            <label :class="{'required': element.is_required}">{{ element.title }}</label>
            <y-form-range
                :value="model.value"
                :min="Number(element.min)"
                :min_title="element.min_title"
                :max="Number(element.max)"
                :max_title="element.max_title"
                contained
                silent
                no-label
                :disabled="readonly"
                @input="change"
            />
        </template>
    </y-panel>
</template>

<script>
    import { digits } from '@nodes/helpers/number';
    import YChart from '@deps/Chart';
    import TypeFrontMixin from '@/modules/form-maker/mixins/TypeFront';
    import YFormRange from '@deps/form/elements/Range';

    export default {
        name: 'FormMakerPreviewRange',

        components: {
            YChart,
            YFormRange,
        },

        mixins: [TypeFrontMixin],

        computed: {
            /**
             * Total of the Answers
             */
            totalAnswers() {
                let count = 0;
                Object.keys(this.answers).forEach((key) => {
                    count += this.answers[key].value;
                });
                return count;
            },

            /**
             * Generate xAxis Label
             */
            xAxisLabel() {
                return Object.keys(this.answers).map((i) => `(${this.digitHelper(Math.round((this.answers[i].value * 100) / this.totalAnswers))}% - ${this.digitHelper(this.answers[i].value)}) ${this.digitHelper(this.answers[i].name)}`);
            },

            /**
             * Generate yAxis Label
             */
            yAxisLabel() {
                return ((params) => `${digits(params, this.$i18n.locale)}`);
            },

            /**
             * Chart Options
             */
            options() {
                return {
                    tooltip: {
                        show: false,
                    },
                    yAxis: {
                        type       : 'value',
                        min        : 0,
                        max        : ((value) => value.max + 3),
                        splitNumber: 50,
                        minInterval: 1,
                        axisLabel  : {
                            formatter: ((params) => `${digits((params), this.$i18n.locale)}`),
                        },
                    },
                };
            },

            /**
             * Series of the widget
             */
            series() {
                const data = this.answers;
                return [
                    {
                        type: 'bar',
                        data,
                    },
                ];
            },
        },

        /**
         * Create empty model
         */
        created() {
            if (this.answers) {
                this.$set(this.model, 'value', this.answers);
            } else {
                this.$set(this.model, 'value', this.element.min);
            }
        },

        methods: {
            /**
             * Get value from range
             *
             * @param value
             */
            change(value) {
                this.$set(this.model, 'value', value);
            },
        },
    };

</script>
