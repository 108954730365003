<template>
    <div>
        <y-form-field
            v-model="model.title"
            type="text"
            name="title"
            :label="$t('form-maker.question.fields.label')"
            validation="required"
            :readonly="!canEdit"
        />
        <y-form-field
            v-model="model.description"
            type="textarea"
            name="description"
            :label="$t('form-maker.question.fields.description')"
            :readonly="!canEdit"
        />
    </div>
</template>

<script>

    import YFormField from '@deps/form/FormField';

    export default {
        name: 'FormMakerQTitle',

        components: {
            YFormField,
        },

        props: {
            value  : [Object, Array],
            canEdit: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },
    };

</script>
