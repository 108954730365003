<template>
    <y-loading
        class="form-maker-preview"
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <y-page-head
            :title="pageHeadTitle"
            icon="md-file-outline"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <!-- Close -->
                <y-button
                    v-if="$route.query.preview"
                    color="red"
                    @click.prevent="closeTab"
                >
                    {{ $t('button.close') }}
                </y-button>
                <y-button
                    v-else
                    color="red"
                    tag="router-link"
                    :to="backToGrid"
                    @click.prevent="closeTab"
                >
                    {{ $t('button.close') }}
                </y-button>
            </template>
        </y-page-head>

        <main>
            <form
                id="main-preview-form"
                @submit.prevent="submit(save)"
            >
                <div
                    v-if="form"
                    class="row"
                >
                    <div class="col-sm-6 col-center">
                        <form-wizard
                            class="form-wizard no-title"
                            :class="{'form-wizard__rtl': $i18n.locale === 'fa'}"
                            :next-button-text="$t('form-maker.wizard.button.next')"
                            :back-button-text="$t('form-maker.wizard.button.back')"
                            :finish-button-text="$t('form-maker.wizard.button.finish')"
                            color="#1875F0"
                            @on-complete="submit(save)"
                        >
                            <tab-content
                                v-for="(section, index) in form.sections"
                                :key="`section-${section.id}`"
                                :title="section.title"
                                icon="icon md-checkbox-blank-circle"
                            >
                                <component
                                    :is="componentType(component.type)"
                                    v-for="(component, i) in components[index]"
                                    :key="component.id"
                                    class="form-maker__preview-component"
                                    :element="component"
                                    @input="modelInput($event, index, i)"
                                />
                            </tab-content>
                        </form-wizard>
                    </div>
                </div>
            </form>
        </main>
    </y-loading>
</template>

<script>

    import { FormWizard, TabContent } from 'vue-form-wizard';
    import 'vue-form-wizard/dist/vue-form-wizard.min.css';

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import FormComponentMixin from '@/modules/form-maker/mixins/FormComponent';

    export default {
        name: 'FormMakerPreview',

        components: {
            FormWizard,
            TabContent,
        },
        
        mixins: [
            PageMixin,
            FormMixin,
            FormComponentMixin,
        ],


        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.form.form_maker')),
                this.$bc(this.$t('breadcrumbs.form.preview')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('form-maker.preview.page_head'),
            };
        },

        computed: {
            /**
             * Page Head Title
             */
            pageHeadTitle() {
                return this.form ? `${this.$t('form-maker.preview.page_head_title')}: ${this.form.title}` : this.$t('form-maker.preview.page_head_title');
            },

            /**
             * Back to Forms Grid
             */
            backToGrid() {
                return {
                    name  : 'panel-grid',
                    params: {
                        class  : 'index',
                        module : 'FormMaker',
                        part   : 'list',
                        version: 1,
                    },
                };
            },
        },

        methods: {
            /**
             * Fetch data
             */
            fetch() {
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.FormMaker.preview(params).then((response) => {
                    if (response.data.results) {
                        this.model = [];
                        this.$set(this, 'form', response.data.results);
                        this.sortSections(this.form.sections);
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save Form
             */
            save() {
                const params = {
                    id   : this.$route.params.id,
                    items: this.model,
                };
                return this.$services.FormMaker.saveAnswers(params).then(() => {
                    this.$toast.success(this.$t('form-maker.preview.send_success_msg'));
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Get component data
             *
             * @param event
             * @param index
             * @param i
             */
            modelInput(event, index, i) {
                if (!this.model[index]) {
                    this.$set(this.model, `${index}`, []);
                }
                if (event) {
                    this.$set(this.model[index], `${i}`, event);
                }
            },

            /**
             * Close Preview Tab
             */
            closeTab() {
                // eslint-disable-next-line no-alert
                if (window.confirm(this.$t('form-maker.preview.close_preview'))) {
                    window.close();
                }
            },
        },
    };

</script>
