<template>
    <div>
        <!-- Question General Fields: Title and Type -->
        <div class="row">
            <div class="col-sm-8">
                <y-form-field
                    v-model="model.title"
                    type="text"
                    name="title"
                    :label="$t('form-maker.question.fields.label')"
                    validation="required"
                    :readonly="!canEdit"
                />
            </div>
            <div class="col-sm-4">
                <y-form-field
                    v-model="model.type"
                    type="select"
                    name="type"
                    :label="$t('form-maker.question.fields.type')"
                    :options="setTypes"
                    label-field="label"
                    value-field="value"
                    validation="required"
                    :readonly="!canEdit"
                    @select="selectType($event)"
                />
            </div>
        </div>

        <!-- Dropdown, Checkbox and Radio Answers -->
        <div
            v-if="hasAnswer"
            class="form-maker__answers-wrapper"
        >
            <y-panel
                v-if="model.options && model.options.length"
                class="mb0"
                :title="$t('form-maker.options.title')"
            >
                <template slot="functions">
                    <y-button
                        v-if="canEdit"
                        size="sm"
                        @click.native.prevent="addOption('add')"
                    >
                        {{ $t('form-maker.section.toolbar.add_option') }}
                    </y-button>
                </template>

                <draggable
                    ref="Draggable"
                    tag="div"
                    handle="div .panel > div > article .handler .md-menu"
                    :list="model.options"
                >
                    <template v-for="(item, index) in model.options">
                        <div
                            v-if="!item.deleted"
                            :key="index"
                            class="handler d-f justify-content-start align-items-center"
                        >
                            <span class="handler-icon">
                                <i class="icon md-menu" />
                            </span>
                            <y-form-field
                                v-model="model.options[index].title"
                                type="text"
                                name="title"
                                :placeholder="$t('form-maker.options.fields.label')"
                                no-label
                                :readonly="!canEdit"
                            />
                            <y-button
                                v-if="canEdit"
                                size="sm"
                                color="red"
                                icon="md-delete"
                                class="handler-delete"
                                @click.native.prevent="$refs.deleteModal.open(index, 'option', item.id)"
                            />
                        </div>
                    </template>
                </draggable>
            </y-panel>

            <y-empty-state
                v-else
                height="250"
                :message="$t('form-maker.options.empty')"
            />
        </div>

        <!-- Date and Time -->
        <div
            v-if="model.type === 'date_time'"
            class="row"
        >
            <div class="col-sm-6">
                <y-form-field
                    v-model="model.input_type"
                    type="select"
                    name="ask_type"
                    :label="$t('form-maker.question.fields.date.ask_type')"
                    :options="input_type"
                    label-field="label"
                    value-field="value"
                    validation="required"
                    :readonly="!canEdit"
                />
            </div>
            <div class="col-sm-6">
                <y-form-field
                    v-model="model.calendar_type"
                    type="select"
                    name="date_type"
                    :label="$t('form-maker.question.fields.date.date_type')"
                    :options="calendar_type"
                    label-field="label"
                    value-field="value"
                    :validation="model.input_type === 'time' ? '' : 'required'"
                    :disabled="model.input_type === 'time'"
                    :readonly="!canEdit"
                />
            </div>
        </div>

        <!-- Range -->
        <div v-if="model.type === 'range'">
            <div class="row">
                <div class="col-sm-6">
                    <y-form-field
                        v-model="model.min_title"
                        type="text"
                        name="min_title"
                        :label="$t('form-maker.question.fields.range.min_title')"
                        validation="required"
                        :readonly="!canEdit"
                    />
                </div>
                <div class="col-sm-6">
                    <y-form-field
                        v-model="model.min"
                        type="number"
                        format="separated"
                        name="min_amount"
                        :label="$t('form-maker.question.fields.range.min_amount')"
                        validation="required"
                        :readonly="!canEdit"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <y-form-field
                        v-model="model.max_title"
                        type="text"
                        name="max_title"
                        :label="$t('form-maker.question.fields.range.max_title')"
                        validation="required"
                        :readonly="!canEdit"
                    />
                </div>
                <div class="col-sm-6">
                    <y-form-field
                        v-model="model.max"
                        type="number"
                        format="separated"
                        name="max_amount"
                        :label="$t('form-maker.question.fields.range.max_amount')"
                        validation="required"
                        :readonly="!canEdit"
                    />
                </div>
            </div>
        </div>

        <!-- File -->
        <div v-if="model.type === 'file'">
            <y-form-field
                v-model="model.rule"
                type="select"
                name="upload_rule"
                :label="$t('form-maker.question.fields.upload_rule')"
                :options="uploaderRules"
                label-field="label"
                value-field="value"
                validation="required"
                :readonly="!canEdit"
            />
        </div>

        <!-- Question General Fields: Is_required -->
        <y-form-field
            v-model="model.is_required"
            type="checkbox"
            name="required"
            class="mb0"
            :label="$t('form-maker.question.fields.required')"
            :readonly="!canEdit"
        />

        <y-delete-modal
            ref="deleteModal"
            @confirm="deleteOption"
        />
    </div>
</template>

<script>

    import Draggable from 'vuedraggable';
    import YFormField from '@deps/form/FormField';
    import YEmptyState from '@deps/EmptyState';
    import YDeleteModal from '@/modules/form-maker/components/modal/Delete';

    export default {
        name: 'FormMakerQuestion',

        components: {
            Draggable,
            YFormField,
            YEmptyState,
            YDeleteModal,
        },

        props: {
            value        : [Object, Array],
            types        : Array,
            uploaderRules: Array,
            canEdit      : Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},

                option: {
                    title  : null,
                    deleted: false,
                },

                // Date and time selects
                input_type: [
                    {
                        label: this.$t('form-maker.question.fields.date.date_time'),
                        value: 'date_time',
                    },
                    {
                        label: this.$t('form-maker.question.fields.date.date'),
                        value: 'date',
                    },
                    {
                        label: this.$t('form-maker.question.fields.date.time'),
                        value: 'time',
                    },
                ],
                calendar_type: [
                    {
                        label: this.$t('form-maker.question.fields.date.automatic'),
                        value: 'automatic',
                    },
                    {
                        label: this.$t('form-maker.question.fields.date.jalali'),
                        value: 'jalaali',
                    },
                    {
                        label: this.$t('form-maker.question.fields.date.gregorian'),
                        value: 'gregorian',
                    },
                ],
            };
        },

        computed: {
            /**
             * Check if it's in edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Check if the question type accept answer
             */
            hasAnswer() {
                let has = false;
                if (this.model.type === 'dropdown' || this.model.type === 'checkboxes' || this.model.type === 'radio') {
                    has = true;
                }
                if (has && !this.model.options) {
                    this.addOption('add');
                }
                return has;
            },

            /**
             * Get Question types
             */
            setTypes() {
                return this.types ? this.types : [];
            },
        },

        watch: {
            /**
             * Watch model to emit the value
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    if (this.model.input_type === 'time') {
                        this.model.calendar_type = 'automatic';
                    }
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (this.hasAnswer && !this.model.options) {
                this.addOption('add');
            }
        },

        methods: {
            /**
             * Add Options
             *
             * @param method
             * @param type
             * @param index
             */
            addOption(method, type, index) { // eslint-disable-line no-unused-vars
                if (!this.model.options) {
                    this.$set(this.model, 'options', []);
                }
                    
                if (method === 'add') {
                    this.$set(this.model, 'options', [...this.model.options, this.cloneDeep(this.option)]);
                }
            },

            /**
             * Delete Option
             *
             * @param index
             */
            deleteOption(index) {
                this.$set(this.model.options[index], 'deleted', true);
            },

            /**
             * Actions to do after type select
             *
             * @param event
             */
            selectType(event) {
                this.$set(this.model, 'type', event.value);
                if (event.value === 'range') {
                    this.$set(this.model, 'min_title', null);
                    this.$set(this.model, 'min', null);
                    this.$set(this.model, 'max_title', null);
                    this.$set(this.model, 'max', null);
                }
                if (event.value !== 'range') {
                    delete this.model.min_title;
                    delete this.model.min;
                    delete this.model.max_title;
                    delete this.model.max;
                }
            },
        },
    };

</script>
