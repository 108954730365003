<template>
    <y-panel
        :title="summary ? element.title : null"
        :subtitle="summary ? `(${digitHelper(answers.length)})` : null"
        class="field form-wizard-custom-label"
    >
        <template v-if="summary">
            <span
                v-for="(answer, index) in answers"
                :key="index"
                class="answer-date"
            >
                {{ answer | digits }}
            </span>
        </template>

        <template v-if="component">
            <template v-if="answers && answers.length">
                <p
                    v-for="(answer, index) in answers"
                    :key="index"
                    class="answer-input is-component"
                >
                    <span>{{ answer.value | digits }}</span>
                    <span class="answer-count">{{ answer.count | digits }}</span>
                </p>
            </template>

            <template v-else>
                <y-empty-state
                    height="150"
                    class="mb0"
                    no-image
                    :title="$t('form-maker.responses.individual.empty_response')"
                />
            </template>
        </template>

        <template v-else-if="!summary && !component">
            <label :class="{'required': element.is_required}">{{ element.title }}</label>
            <y-form-field
                v-if="answers"
                :value="digitHelper(model.value)"
                type="text"
                :placeholder="element.placeholder ? element.placeholder : ''"
                no-label
                :validation="element.is_required ? 'required' : ''"
                :readonly="readonly"
            />

            <y-form-date-picker
                v-else
                v-model="model.value"
                :type="inputType"
                :format="format"
                :calendar="calendarType"
                no-label
                :locale-config="localeConfig"
                class="mb0"
                :validation="element.is_required ? 'required' : ''"
                :readonly="readonly"
            />
        </template>
    </y-panel>
</template>

<script>

    import TypeFrontMixin from '@/modules/form-maker/mixins/TypeFront';
    import YFormDatePicker from '@deps/form/elements/DatePicker';

    export default {
        name: 'FormMakerPreviewDateTime',

        components: {
            YFormDatePicker,
        },

        mixins: [TypeFrontMixin],

        computed: {
            /**
             * Set Calendat Type
             */
            calendarType() {
                let type = [this.element.calendar_type];
                if (this.element.calendar_type === 'automatic') {
                    type = this.$i18n.locale === 'fa' ? ['jalaali'] : ['gregorian'];
                }
                return type;
            },

            /**
             * Locale Config
             */
            localeConfig() {
                return {
                    fa: {
                        displayFormat: 'jYYYY/jMM/jDD',
                    },
                    en: {
                        displayFormat: 'YYYY/MM/DD',
                    },
                };
            },

            /**
             * Set Input Type
             */
            inputType() {
                return this.element.input_type === 'date_time' ? 'datetime' : this.element.input_type;
            },

            /**
             * Set the format
             */
            format() {
                let format = 'YYYY/MM/DD';
                if (this.inputType === 'time') {
                    format = 'HH:mm';
                }
                if (this.inputType === 'datetime') {
                    format = 'YYYY/MM/DD HH:mm';
                }
                return format;
            },
        },

        /**
         * Add Answer to the model
         */
        created() {
            if (this.answers) {
                this.$set(this.model, 'value', this.answers);
            }
        },
    };

</script>
