<template>
    <y-loading
        class="form-maker__responses"
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <y-page-head
            :title="pageHeadTitle"
            :desc="pageHeadDesc"
            icon="md-file-outline"
        >
            <template
                v-if="metadata"
                slot="actions"
            >
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    @click.prevent="reFetch"
                />

                <y-button
                    v-if="metadata && metadata.batches_count"
                    id="clear-response-button"
                    type="button"
                    color="red"
                    loading-on="delete-all-answers"
                    @click.prevent="$refs.deleteAnswersModal.open()"
                >
                    {{ `${$t('form-maker.responses.buttons.delete_all')} (${digitHelper(responseCount)})` }}
                </y-button>

                <y-button
                    v-if="metadata && metadata.batches_count"
                    id="clear-response-button"
                    type="button"
                    loading-on="download-form-exported-excel"
                    :disabled="!metadata.exported_excel"
                    @click.prevent="downloadExportedExcel"
                >
                    {{ $t('form-maker.responses.buttons.download_exported_excel') }}
                </y-button>

                <y-button
                    v-if="metadata && metadata.batches_count"
                    id="clear-response-button"
                    type="button"
                    color="green"
                    loading-on="download-form-excel"
                    @click.prevent="downloadExcel"
                >
                    {{ $t('form-maker.responses.buttons.download_excel') }}
                </y-button>

                <y-button
                    v-if="metadata && metadata.is_active"
                    id="disable-form-button"
                    type="button"
                    loading-on="change-form-activity"
                    @click.prevent="disableForm"
                >
                    {{ $t('form-maker.responses.buttons.disable_form') }}
                </y-button>

                <y-button
                    v-else
                    id="disable-form-button"
                    type="button"
                    loading-on="change-form-activity"
                    @click.prevent="disableForm"
                >
                    {{ $t('form-maker.responses.buttons.enable_form') }}
                </y-button>
            </template>

            <!-- Tabs -->
            <template slot="tabs">
                <template v-for="tab in tabs">
                    <a
                        v-if="tab.status"
                        :key="`tab-title-${tab.slug}`"
                        href="#"
                        :class="{active: activeTab === tab.slug}"
                        @click.prevent="changeTab(tab.slug)"
                    >
                        <i
                            v-if="tab.icon"
                            class="icon"
                            :class="[tab.icon]"
                        />
                        {{ tab.title }}
                    </a>
                </template>
            </template>
        </y-page-head>
        
        <main>
            <component
                :is="tabComponent"
                :ref="tabComponent"
                :forms="form"
                :meta="metadata"
            />
        </main>

        <y-delete-answers-modal
            ref="deleteAnswersModal"
            @confirm="deleteAllResponses"
        />
    </y-loading>
</template>

<script>
    import { capitalize } from '@nodes/helpers/string';
    import { digits } from '@nodes/helpers/number';
    import PageMixin from '@/mixins/Page';
    import { YFormField } from '@deps';
    import YResponseSummaryTab from '@/modules/form-maker/components/Responses/Tabs/Summary';
    import YResponseQuestionsTab from '@/modules/form-maker/components/Responses/Tabs/Questions';
    import YResponseIndividualTab from '@/modules/form-maker/components/Responses/Tabs/individual';
    import YDeleteAnswersModal from '@/modules/form-maker/components/modal/DeleteAnswers';

    export default {
        name: 'FormMakerResponses',

        components: {
            YFormField,
            YResponseSummaryTab,
            YResponseQuestionsTab,
            YResponseIndividualTab,
            YDeleteAnswersModal,
        },

        mixins: [
            PageMixin,
        ],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.form.form_maker')),
                this.$bc(this.$t('breadcrumbs.form.responses')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('form-maker.responses.title'),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                form    : null,
                metadata: null,

                activeTab: 'summary',
                formInfo : {},
            };
        },

        computed: {
            /**
             * Return the title for the Page Head
             */
            pageHeadTitle() {
                return this.form && this.form.title ? this.form.title : this.$t('form-maker.responses.title');
            },

            /**
             * Return the description of the page head
             */
            pageHeadDesc() {
                return this.$t('form-maker.responses.desc', [this.metadata && this.metadata.batches_count ? this.digitHelper(this.metadata.batches_count) : this.digitHelper(0)]);
            },

            /**
             * Return the response count
             */
            responseCount() {
                return this.metadata && this.metadata.batches_count ? this.metadata.batches_count : 0;
            },

            /**
             * Return the tabs of the response page
             */
            tabs() {
                return [
                    {
                        title : this.$t('form-maker.responses.tabs.summary'),
                        slug  : 'summary',
                        status: true,
                    },
                    {
                        title : this.$t('form-maker.responses.tabs.questions'),
                        slug  : 'questions',
                        status: !!this.responseCount,
                    },
                    {
                        title : this.$t('form-maker.responses.tabs.individual'),
                        slug  : 'individual',
                        status: !!this.responseCount,
                    },
                ];
            },

            /**
             * Current Tab Component
             */
            tabComponent() {
                return `YResponse${capitalize(this.activeTab)}Tab`;
            },

            /**
             * Return the status of the form
             */
            formStatus() {
                return this.metadata && this.metadata.is_active ? !!this.metadata.is_active : false;
            },
        },

        methods: {
            /**
             * Fetch Summary
             */
            fetch() {
                this.$wait.start('fetch-summary');
                this.metadata = null;
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.FormMaker.formAnswers(params).then((response) => {
                    this.$set(this, 'form', response.data.results);
                    this.$set(this, 'metadata', response.data.metadata);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetch-summary');
                });
            },

            /**
             * Change tab
             * 
             * @param {string} tab - Slug of the active tab
             */
            changeTab(tab) {
                this.activeTab = tab;
            },

            /**
             * Download Excel of the Form
             */
            downloadExcel() {
                this.$wait.start('download-form-excel');
                const params = {
                    tab     : 'exports',
                    provider: 'form_answers',
                    form_id : this.$route.params.id,

                };
                return this.$services.DataTrader.dataTradesSave(params).then(() => {
                    this.$toast.success(this.$t('form-maker.responses.messages.download'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('download-form-excel');
                });
            },

            /**
             * Download Excel of the Form
             */
            downloadExportedExcel() {
                this.$wait.start('download-form-exported-excel');
                const params = {
                    tab: 'exports',
                    id : this.metadata.exported_excel,

                };
                return this.$services.DataTrader.downloadFile(params).then((response) => {
                    window.open(response.data.metadata.redirect.url, response.data.metadata.redirect.target);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('download-form-exported-excel');
                });
            },

            /**
             * Delete All Responses
             */
            deleteAllResponses() {
                this.$wait.start('delete-all-answers');
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.FormMaker.removeAnswers(params).then(() => {
                    this.$toast.success(this.$t('form-maker.responses.messages.delete_response_all'));
                    this.fetch();
                    this.activeTab = 'summary';
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('delete-all-answers');
                });
            },

            /**
             * Disable/Enable the form
             */
            disableForm() {
                this.$wait.start('change-form-activity');
                const params = {
                    id      : this.$route.params.id,
                    activity: this.metadata.is_active ? 0 : 1,
                };
                return this.$services.FormMaker.changeActivity(params).then(() => {
                    this.$toast.success(this.$t('form-maker.responses.messages.change_activity'));
                    this.fetch();
                    this.activeTab = 'summary';
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('change-form-activity');
                });
            },

            /**
             * Digit helper for using in inline condition
             *
             * @param value
             */
            digitHelper(value) {
                return digits(value, this.$i18n.locale);
            },
        },
    };
</script>
