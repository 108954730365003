<template>
    <y-panel
        :title="summary ? element.title : null"
        :subtitle="summary ? `(${digitHelper(answers.length)})` : null"
    >
        <template v-if="summary">
            <span
                v-for="(answer, index) in answers"
                :key="index"
                class="answer-input"
            >
                {{ answer }}
            </span>
        </template>

        <template v-if="component">
            <template v-if="answers && answers.length">
                <p
                    v-for="(answer, index) in answers"
                    :key="index"
                    class="answer-input is-component"
                >
                    <span>{{ answer.value }}</span>
                    <span class="answer-count">{{ answer.count | digits }}</span>
                </p>
            </template>

            <template v-else>
                <y-empty-state
                    height="150"
                    class="mb0"
                    no-image
                    :title="$t('form-maker.responses.individual.empty_response')"
                />
            </template>
        </template>

        <template v-else-if="!summary && !component">
            <y-form-field
                v-model="model.value"
                type="text"
                :placeholder="element.placeholder ? element.placeholder : ''"
                :label="element.title"
                :validation="element.is_required ? 'required' : ''"
                :readonly="readonly"
            />
        </template>
    </y-panel>
</template>

<script>
    import TypeFrontMixin from '@/modules/form-maker/mixins/TypeFront';

    export default {
        name: 'FormMakerPreviewInput',

        mixins: [TypeFrontMixin],

        /**
         * Add Answer to the model
         */
        created() {
            if (this.answers) {
                this.$set(this.model, 'value', this.answers);
            }
        },
    };

</script>
