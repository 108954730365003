// Type Component in Front Mixin

import { digits } from '@nodes/helpers/number';
import YFormField from '@deps/form/FormField';
import YFormDatePicker from '@deps/form/elements/DatePicker';
import YEmptyState from '@deps/EmptyState';

export default {
    name: 'TypeFrontMixin',

    components: {
        YFormField,
        YFormDatePicker,
        YEmptyState,
    },

    props: {
        /**
         * Element Object
         */
        element: [Object, Array],

        /**
         * Responses
         */
        responses: [Object, Array],

        /**
         * If the type should be readonly
         */
        readonly: Boolean,

        /**
         * If the type should show summary
         */
        summary: Boolean,

        /**
         * If the type is for showing just one component
         */
        component: Boolean,
    },
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            model: {
                id          : null,
                component_id: null,
                value       : null,
            },
            answers: null,
        };
    },

    /**
     * Create empty model
     */
    created() {
        this.$set(this.model, 'component_id', this.element && this.element.id ? this.element.id : null);

        if ((this.element && this.element.answer) || this.responses) {
            this.$set(this, 'answers', this.element && this.element.answer ? this.element.answer : this.responses);
        }
    },

    watch: {
        /**
         * Watch model and emit on change
         */
        model: {
            // eslint-disable-next-line require-jsdoc
            handler() {
                this.$emit('input', this.model);
            },
            deep: true,
        },
    },

    methods: {
        /**
         * Download chart
         *
         * @param ref
         */
        downloadChart(ref) {
            const img = new Image();
            img.src = this.$refs[ref].$refs.chart.getDataURL({
                type           : 'jpg',
                pixelRatio     : 2,
                backgroundColor: '#fff',
            });
            fetch(img.src)
                .then((res) => res.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = ref;
                    // eslint-disable-next-line require-jsdoc
                    const clickHandler = () => {
                        setTimeout(() => {
                            URL.revokeObjectURL(url);
                            a.removeEventListener('click', clickHandler);
                        }, 150);
                    };
                    a.addEventListener('click', clickHandler, false);
                    a.click();
                    return a;
                });
        },

        /**
         * Digit helper for using in inline condition
         *
         * @param value
         */
        digitHelper(value) {
            return digits(value, this.$i18n.locale);
        },
    },
};
