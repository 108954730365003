<template>
    <y-row class="form-maker__responses__questions">
        <y-col
            sm="12"
            md="8"
            center
        >
            <y-loading
                :active="$wait.is('fetch-response')"
                height="300px"
            >
                <template v-if="type && response">
                    <component
                        :is="componentType(type)"
                        :responses="response"
                        component
                    />
                </template>
            </y-loading>
        </y-col>

        <y-col
            sm="12"
            md="4"
        >
            <y-loading
                :active="$wait.is('fetch-questions')"
                height="300px"
            >
                <y-panel
                    v-if="questionsList"
                    :title="$t('form-maker.responses.questions.list')"
                >
                    <y-form-field
                        v-model="question"
                        type="select"
                        :options="questionsList"
                        label-field="title"
                        value-field="id"
                        no-label
                        @select="selectedComponent($event)"
                    />
                    <y-row>
                        <y-col>
                            <y-button
                                id="downlaod-question-excel-button"
                                type="button"
                                color="green"
                                block
                                loading-on="download-question-excel"
                                @click.prevent="downloadQuestionResponseExcel"
                            >
                                {{ $t('form-maker.responses.buttons.download_excel') }}
                            </y-button>
                        </y-col>
                        <y-col>
                            <y-button
                                id="downlaod-question-excel-button"
                                type="button"
                                block
                                loading-on="download-question-exported-excel"
                                :disabled="metadata && !metadata.exported_excel"
                                @click.prevent="downloadQuestionResponseExportedExcel"
                            >
                                {{ $t('form-maker.responses.buttons.download_exported_excel') }}
                            </y-button>
                        </y-col>
                    </y-row>
                </y-panel>
            </y-loading>
        </y-col>
    </y-row>
</template>

<script>
    import FormComponentMixin from '@/modules/form-maker/mixins/FormComponent';
    import { YFormField } from '@deps';

    export default {
        name: 'ResponseQuestionsTab',

        components: {
            YFormField,
        },

        mixins: [FormComponentMixin],

        /**
         * @inheritdoc
         */
        data() {
            return {
                question     : null,
                questionsList: null,
                metadata     : null,
                response     : null,
                type         : null,
                model        : null,
            };
        },

        /**
         * @inheritdoc
         */
        created() {
            this.fetch();
            this.fetchQuestions();
        },

        methods: {
            /**
             * Fetch data
             * 
             * @param {string} id - Id of the component
             */
            fetch(id) {
                this.$wait.start('fetch-response');
                const params = {
                    id: this.$route.params.id,
                };
                if (id) {
                    params.component_id = id;
                }
                return this.$services.FormMaker.formsComponentAnswers(params).then((response) => {
                    this.$set(this, 'response', response.data.results);
                    this.$set(this, 'metadata', response.data.metadata);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetch-response');
                });
            },

            /**
             * Fetch Questions
             */
            fetchQuestions() {
                this.$wait.start('fetch-questions');
                this.$services.single('form-maker-form', this.$route.params.id, { including: ['components'] }).then((response) => {
                    this.$set(this, 'questionsList', response.data.results.components);
                    if (!this.question && this.questionsList.length) {
                        this.$set(this, 'question', this.questionsList[0].id);
                        this.$set(this, 'type', this.questionsList[0].type);
                    }
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetch-questions');
                });
            },

            /**
             * Set User after user change
             * 
             * @param {object} component - Object of the selected component
             */
            selectedComponent(component) {
                this.fetch(component.id);
                this.$set(this, 'type', component.type);
            },

            /**
             * Download User Response in Excel
             */
            downloadQuestionResponseExcel() {
                this.$wait.start('download-question-excel');
                const params = {
                    tab         : 'exports',
                    provider    : 'form_answers',
                    form_id     : this.$route.params.id,
                    component_id: this.question,

                };
                return this.$services.DataTrader.dataTradesSave(params).then(() => {
                    this.$toast.success(this.$t('form-maker.responses.messages.download'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('download-question-excel');
                });
            },

            /**
             * Download Exported Excel
             */
            downloadQuestionResponseExportedExcel() {
                this.$wait.start('download-question-exported-excel');
                const params = {
                    tab: 'exports',
                    id : this.metadata.exported_excel,

                };
                return this.$services.DataTrader.downloadFile(params).then((response) => {
                    window.open(response.data.metadata.redirect.url, response.data.metadata.redirect.target);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('download-question-exported-excel');
                });
            },
        },
    };
</script>
