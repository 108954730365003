<template>
    <y-loading
        class="form-maker"
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <y-page-head
            :title="pageHeadTitle"
            icon="md-file-outline"
        >
            <template slot="actions">
                <y-button
                    v-if="isEdit"
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    v-if="isEdit ? (metadata && metadata.can_edit) : true"
                    color="blue"
                    form="main-form"
                    loading-on="submitting-form"
                    :disabled="!hasActiveSections"
                >
                    {{ isEdit ? $t('button.edit') : $t('button.save') }}
                </y-button>

                <!-- Preview -->
                <y-button
                    v-if="isEdit && (metadata && metadata.can_view)"
                    tag="router-link"
                    :to="previewLink"
                    target="_blank"
                    color="green"
                >
                    {{ $t('form-maker.preview.button') }}
                </y-button>
            </template>
        </y-page-head>

        <main>
            <form
                id="main-form"
                @submit.prevent="submit(save)"
            >
                <div class="row">
                    <div class="col-sm-8">
                        <y-form-maker-form-wrapper
                            v-model="model"
                            :metadata="metadata"
                            :types="types"
                            :uploader-rules="uploaderRules"
                        />
                    </div>
                    <div class="col-sm-4">
                        <y-form-maker-setting
                            v-model="model"
                            :metadata="metadata"
                        />
                    </div>
                </div>
            </form>
        </main>
    </y-loading>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    
    import YFormMakerSetting from '@/modules/form-maker/components/Setting';
    import YFormMakerFormWrapper from '@/modules/form-maker/components/FormWrapper';

    export default {

        name: 'FormMakerEditor',

        components: {
            YFormMakerSetting,
            YFormMakerFormWrapper,
        },

        mixins: [
            PageMixin,
            FormMixin,
        ],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.form.form_maker')),
                this.$bc(this.$route.params.id ? this.$t('breadcrumbs.form.edit') : this.$t('breadcrumbs.form.create')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('form-maker.page_head'),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model            : {},
                types            : null,
                uploaderRules    : null,
                hasActiveSections: false,
                metadata         : null,
            };
        },

        computed: {
            /**
             * Check if it's in edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Page Head Title
             */
            pageHeadTitle() {
                return this.$t('form-maker.page_head');
            },

            /**
             * Preview Link
             */
            previewLink() {
                return {
                    name  : 'form-maker-preview',
                    params: {
                        id: this.$route.params.id,
                    },
                    query: {
                        preview: generateId(),
                    },
                };
            },
        },

        watch: {
            /**
             * Get Help after route changed
             */
            '$route.name': function () { // eslint-disable-line func-names
                if (this.$route.name === 'form-maker-create') {
                    this.model = {};
                }
            },
                
            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.hasSection();
                },
                deep: true,
            },
        },

        methods: {
            /**
             * Fetch data
             */
            fetch() {
                const params = {};
                if (this.isEdit) {
                    params.id = this.$route.params.id;
                }
                return this.$services.FormMaker.fetch(params).then((response) => {
                    if (this.isEdit && response.data.results) {
                        this.$set(this, 'model', response.data.results);
                    }
                    if (response.data.metadata && response.data.metadata.types) {
                        this.$set(this, 'types', response.data.metadata.types);
                        this.$set(this, 'uploaderRules', response.data.metadata.uploader_rules);
                    }
                    this.metadata = response.data.metadata;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save order
             */
            save() {
                const params = {
                    ...this.model,
                };
                return this.$services.FormMaker.save(params).then((response) => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('form-maker.type') }));

                    if (!this.isEdit) {
                        this.$router.push({
                            name  : 'form-maker-edit',
                            params: {
                                id: response.data.metadata.id,
                            },
                        });
                    }

                    this.reFetch();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Check if there is active section
             */
            hasSection() {
                let has = false;
                if (this.model && this.model.sections) {
                    this.model.sections.forEach((i) => {
                        if (!i.deleted) {
                            has = true;
                        }
                    });
                }
                this.$set(this, 'hasActiveSections', has);
            },
        },

    };

</script>
