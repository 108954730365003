<template>
    <div
        class="form-maker__add-bar"
        :class="{'line': insert}"
    >
        <span
            v-if="insert"
            class="insert-button"
            @click="$emit('open')"
        >
            <i class="icon md-plus-circle-outline" />
        </span>
        <y-button
            v-else
            @click.native.prevent="$emit('open')"
        >
            {{ buttonLabel }}
        </y-button>
    </div>
</template>

<script>

    export default {
        name: 'FormMakerAddBar',

        props: {
            label : String,
            insert: Boolean,
        },

        computed: {
            /**
             * Get Button Label or use default
             */
            buttonLabel() {
                return this.label ? this.label : this.$t('form-maker.section.add.title');
            },
        },
    };

</script>
