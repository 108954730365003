<template>
    <y-panel class="form-title">
        <h5 class="__title">
            {{ element.title }}
        </h5>
        <p class="__desc mb0">
            {{ element.description }}
        </p>
    </y-panel>
</template>

<script>
    import TypeFrontMixin from '@/modules/form-maker/mixins/TypeFront';

    export default {
        name: 'FormMakerPreviewTitle',

        mixins: [TypeFrontMixin],
    };

</script>
