<template>
    <y-modal
        ref="Modal"
        :title="$t('form-maker.section.delete.title', [$t(`form-maker.section.${model}`)])"
    >
        <div slot="body">
            <p :class="hasAnswer ? '' : 'mb0'">
                {{ $t('form-maker.section.delete.text', [$t(`form-maker.section.${model}`)]) }}
            </p>

            <y-alert
                v-if="hasAnswer"
                color="red"
                :message="$t('form-maker.section.delete.has_answer')"
            />
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                @click="close"
            >
                {{ $t('button.close') }}
            </y-button>
            <y-button
                color="red"
                @click.prevent.native="deleteItem"
            >
                {{ $t('button.confirm') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>

    import { YModal, YAlert } from '@deps';

    export default {
        name: 'DeleteModal',

        components: {
            YModal,
            YAlert,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                index    : null,
                typeIndex: null,
                model    : null,
                id       : null,
                hasAnswer: null,
            };
        },

        methods: {
            /**
             * Open modal
             *
             * @param index
             * @param model
             * @param id
             * @param typeIndex
             */
            open(index, model, id, typeIndex) {
                this.$set(this, 'index', index);
                this.$set(this, 'model', model);
                this.$set(this, 'id', id);
                this.$set(this, 'typeIndex', typeIndex);
                if (id && model !== 'title') {
                    this.checkAnswers();
                }
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Check if the model has answers
             */
            checkAnswers() {
                const params = {
                    id        : this.id,
                    model_name: this.model,
                };
                return this.$services.FormMaker.checkAnswer(params).then((response) => {
                    this.hasAnswer = response.data.results.has_answer;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Emit Add Section event
             */
            deleteItem() {
                this.$emit('confirm', this.index, this.typeIndex);
                this.close();
            },
        },
    };

</script>
